<template>
  <div>
    <b-alert v-if="!newDesign" :show="true" style="font-size:14px; border-radius:0; border-width:2px">
      A code has been sent to your phone. Please enter it below.
    </b-alert>
    <div class="form-group" :class="{'d-none' : newDesign}">
      <label for="phone">Mobile Number Entered</label>
      <input
        id="phone"
        type="tel"
        @input="formatPhoneNumber"
        class="phone-input form-control"
        required
        minlength="10"
        maxlength="14"
        @keypress="onlyNumber"
        :value="phone">
    </div>
    <div class="form-group">
      <label for="code">Verification code</label>
      <input
        id="code"
        type="number"
        :value="code"
        @input="$emit('update:code', $event.target.value)"
        class="form-control"
        placeholder="Enter the 4 digit code">
        <b-alert v-if="newDesign" :show="true" style="font-size: 12px;border-radius: 0px;border-width: 2px;margin: 5px 0 0 0;padding: 0px 5px;">
          A code has been sent to your phone. Please enter it.
        </b-alert>
    </div>    
  </div>
</template>

<script>
export default {
  name: 'AuthWithPhone',
  props: {
    phone: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    },
    newDesign: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatPhoneNumber(event) {
      let input = event.target.value;
      input = input.replace(/\D/g, ''); // Remove all non-digit characters

        if (input.length <= 3 && input.length > 0) {
          input = input.replace(/^(\d{0,3})/, '($1)');
        } else if (input.length <= 6 && input.length > 0) {
          input = input.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (input.length <= 10 && input.length > 0) {
          input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
      event.target.value = input; // Set the formatted value to the model
      let cleanedValue = input.replace(/\D/g, ''); // Remove all non-digit characters
      this.$emit('update:phone', cleanedValue);
    },
    onlyNumber(event) {
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/auth.scss';
</style>
