import EZAxios from '@/ezaxios';

export default {
  getBrands(page, limit, search, sort) {
    let params = { page, limit, sort };

    if (search)
      params.search = search;
    return EZAxios.get(`brands`, { params });
  },
  getBrandById(id) {
    return EZAxios.get(`brands/brand?param=${id}`);
  },
  getVideos(brand_id) {
    return EZAxios.get(`brands/${brand_id}/videos`);
  },
  getFilter() {
    return EZAxios.get(`brands/get-filter`);
  }
};
