import EZAxios from '@/ezaxios';


const getToken = () => localStorage.getItem('token') || sessionStorage.getItem('token');

export default {
  login(obj, codes, device_id) {
    let telephone = obj.phone && obj.phone;
    let email = obj.email && obj.email;
    let otp = codes.code && codes.code;
    let password = codes.password && codes.password;
    let magicLinkToken = obj.magicLinkToken;
    if(obj.magicLinkToken)
      return EZAxios.post(`use-magic-link`, { email, magicLinkToken, device_id });
    if(obj.phone)
      return EZAxios.post(`verify-otp`, { telephone, otp, device_id });
    else
      return EZAxios.post(`login`, { email, password, device_id });
  },
  loginByToken(data) {
    return EZAxios.post(`login-by-token`, data);
  },
  verifyEmailOrPhone(payload) {
    return EZAxios.post(`check-email-phone-exists`, payload);
  },
  sendOTP(telephone) {
    return EZAxios.post(`send-otp`, { telephone });
  },
  generateMagicLink(email) {
    return EZAxios.post(`send-magic-link`, {email: email});
  },
  logout() {
    return EZAxios.post(`logout?access_token=${getToken()}`);
  },
  forgotPassword(email) {
    return EZAxios.post(`forget-password`, {email: email});
  },
  resetPassword(code, password, email) {
    let data = { code: parseInt(code), password: password, password_confirmation: password, email: email };
    return EZAxios.post(`reset-password`, data);
  },
  mfaLoad() {
    return EZAxios.get('mfa/load', { headers: { Authorization: 'Bearer ' + getToken() } });
  },
  mfaSetup() {
    // { string status, string message, string userType, string qrcode, string secret }
    // status = success | error
    // qrcode is base64 data url
    return EZAxios.post('mfa/setup', {}, { headers: { Authorization: 'Bearer ' + getToken() } });
  },
  mfaSetupComplete(code) {
    // { string status, string[] backupCodes }
    return EZAxios.post('mfa/setup-complete', { code }, { headers: { Authorization: 'Bearer ' + getToken() } });
  },
  mfaDisable() {
    return EZAxios.post('mfa/disable', {}, { headers: { Authorization: 'Bearer ' + getToken() } });
  },
  mfaVerify(code, token, isBackupCode) {
    // same response as login
    // but now login can return {mfa: true, token: string}
    return EZAxios.post('mfa/submit', { code, token, is_backup: isBackupCode ? 1 : 0 })
    .then(response => {
      return response;
    }).catch(error => {
      return {
        data: {
          message: "Your 2FA code is invalid. Please try again.",
          status: 'error',
        },
      };
    });
  },
};
