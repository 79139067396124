export default {
  business_slug: null,
  businessInfo: null,
  widgets: null,
  widgetTypes: null,
  payment_provider: '',
  payment_preparable: false,
  stripe_publishable: '',
  stripe_client_id: '',
  stripe_account_id: '',
  stripe_disable_save: false,
  adyen_origin_key: '',
  adyen_environment: '',
  plugnpay_host: '',
  plugnpay_username: '',
  authorize_net_live: false,
  authorize_net_login_id: '',
  authorize_net_client_key: '',
  authorize_net_show_billing_address: true,
  fiserv_live: false,
  device_id: null,
  activeUser: null,
  products: [],
  popularProducts: null,
  topBrandProducts: [],
  favouriteProducts: null,
  cart: [],
  cartItemCount: 0,
  deliveryFee: 0,
  coupons: [],
  banner: null,
  homepage: null,
  searchSuggestions: null,
  searchBrandProductSuggestions: null,
  searchResults: null,
  timeBasedCache: {},
  rentals: null,
  addingToCart: false,
  fullDepartmentResults: null,
  departmentResults: null,
  departments: null,
  brands: null,
  currentBrand: null,
  preferences: {},
  businessDetails: {},
  hasTrueValueRewards: false,
  currentStore: null,
  storeLocations: [],
  customPages: [],
  footer: [],
  customNavigationGroups: [],
  customNavigation: [],
  allowSpecialOrderVendors: [],
  docsTemplates: {
    policy: 'https://ezadtv.com/ecommerce/policy',
    policyCalifornia: 'https://ezadtv.com/ecommerce/policy-california',
    returnPolicy: 'https://ezadtv.com/ecommerce/return-policy?store=***'
  },
  settings: [],
  isBasicPlan: window.__EZ_TV_ECOM_PLAN === 'LEB' || window.__EZ_TV_ECOM_PLAN === 'E2P',
  company: String(window.__EZ_IS_BENJAMINMOORE).toLowerCase() == 'true' || String(process.env.VUE_APP_IS_BENJAMINMOORE).toLowerCase() == 'true' ? 'benjaminmoore' : String(window.__EZ_IS_TRUEVALUE).toLowerCase() == 'true' || String(process.env.VUE_APP_IS_TRUEVALUE).toLowerCase() == 'true' ? 'truevalue' : null,
  docs: {},
  adminWizardSteps: [],
  adminWizardBusinesses: [],
  adminWizardSelectedStore: [],
  adminWizardShowCongrats: false,
  cartShippingQuotes: [],
  cartFetchingShippingQuotes: false,
  cartSelectedShippingQuote: null,
  cartShippingAddress: {},
  cartShippingAddressValidated: false,
  cartItemsRestricted: false,
  activeShippingCarriers: [],
  paypalCheckoutOrder: null,
  cartStep: 1,
  cartTotals: 0,
  recentVieweditems: null,
  fetchingCartItems: false,
  paint: {
    colors: {},
    families: null,
    collections: null,
    productCategories: null
  }
};
